import { Component, Vue } from 'vue-property-decorator';

import template from './register.vue';
import { Action } from 'vuex-class';
import {
  USER_REGISTER,
  USER_GOOGLE_LOGIN,
  USER_FACEBOOK_LOGIN,
  USER_TWITTER_LOGIN,
  USER_GITHUB_LOGIN
} from '@/store/action-types';
import { ICredentials } from '@/store/modules/user';
import { mapActions } from 'vuex';

@Component({
  methods: {
    ...mapActions({
      loginWithGoogle: `user/${USER_GOOGLE_LOGIN}`,
      loginWithFacebook: `user/${USER_FACEBOOK_LOGIN}`,
      loginWithTwitter: `user/${USER_TWITTER_LOGIN}`,
      loginWithGithub: `user/${USER_GITHUB_LOGIN}`
    })
  },
  mixins: [template]
})
export default class Register extends Vue {
  public form: Partial<{
    username: string;
    password: string;
    passwordConfirmation: string;
  }> = {};

  @Action(USER_REGISTER, { namespace: 'user' })
  private register!: (credentials: ICredentials) => void;

  public async handleSubmit() {
    await this.$validator.validateAll();
    if (this.$validator.errors.count() > 0) {
      return;
    }

    const { username = '', password = '' } = this.form;
    this.register({ username, password });
  }
}
